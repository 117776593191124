.auth_form_bg{
    background: var(--auth_bg);
    border-radius: 35px;
    width: fit-content;
    margin: 80px auto 25px auto;
    padding: 75px 108px 103px 108px;
    color: white;
    max-width: 630px;
}
.auth_input_bg:focus{
    border: 2px solid #E0F87E;
}

.auth_input_bg{
    background: #FFFFFF;
    border-radius: 20px;
    width: 100%;
    
    margin-bottom: 15px;
}
.auth_phone_input::placeholder{
    color: rgba(73, 76, 104, 0.5);

}
.auth_phone_input{
    /* max-width: 414px; */
    border-radius: 20px;
    font-weight: 400;
    font-size: 32px;
    line-height: 22px;
    width: 100%;
    color: var(--dark_gray);
    padding: 22px 22px 21px 22px;

}
.auth_phone_input:focus{
    border: 2px solid #E0F87E;
   padding: 20px 20px 19px 20px;
   
}   
.auth_title{
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 22px;
}
.auth_desc{
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 22px;
}
.back_img{
    position: absolute;
    left: 80px;
    top: 131px;
}
.error{
    border-radius: 0px 0px 35px 35px;
    background: var(--red);
    color: #FFF;
    text-align: center;
    font-size: 14px;
    line-height: 120%;
    padding: 24px;
    margin: 64px -108px -108px -108px;
}
.code_input_container{
    height: 64px;
    margin-top: 48px;
    position: relative;
    display: flex;
    justify-content: center;
}
.hidden_code_input{
    height: 72px;
    width: 340px;
    opacity: 0;
    z-index: 99;
    position: absolute;
    top: -2px;
}
.code_inputs{
    display: flex;
}
.code_input{
    background: #FFFFFF;
    border-radius: 16px;
    width: 65px;
    height: 65px;
    color: #494C68;
    text-align: center;
    font-size: 32px;
    line-height: 120%;
    margin: 0 8px;
    transition: all 300ms;
}
.code_input:focus{
    border: 2px solid #31F8C9;
}
.sms_loader_container{
    margin-top: 86px;
    position: relative;
}
.code_timer{
    color: #FFF;
    text-align: center;
    font-size: 22px;
    margin-top: 99px;
    line-height: 120%;
}
.auth_invalide_code{
    color: var(--red);
    text-align: center;
    font-size: 22px;
    margin-top: 99px;
    line-height: 120%;
}
.auth_next_btn{
    background: #DFF87E;
    box-shadow: 10px 10px 30px rgba(223, 248, 126, 0.5);
    border-radius: 15px;
    width: 100%;
    margin: 72px 0 32px 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    padding: 20px;
    text-align: center;
    transition: all 0.3s;
    max-height: 66px;
    position: relative;
    color: var(--dark_text);
}
.auth_sign_in_btn{
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
}
.auth_sign_in_btn a{
    text-decoration: underline;
    font-weight: 700;
    color: white;
}
.auth_type_btn{
    border: 2px solid #FFFFFF;
    border-radius: 15px;
    padding: 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    /* or 26px */

    text-align: center;
    background: none;
    width: 100%;
    color: #FFFFFF;
}