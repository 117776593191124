body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: env(safe-area-inset-bottom);
  
  }
  
  code {
    font-family: 'Poppins', sans-serif;
  }
  
  html {
    line-height: 1.15;
    /* 1 */
    letter-spacing: .1px;
    -webkit-text-size-adjust: 100%;
    /* 2 */
  }
  
  /* Sections
   ========================================================================== */
  /**
  * Remove the margin in all browsers.
  */
  body {
    outline: none;
    margin: 0;
    background-image: none;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: white;
  }
  
  /**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */
  h1 {
    font-size: 2em;
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Grouping content
   ========================================================================== */
  /**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */
  hr {
    box-sizing: content-box;
    /* 1 */
    height: 0;
    /* 1 */
    overflow: visible;
    /* 2 */
  }
  
  /**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
  pre {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
  }
  
  /* Text-level semantics
   ========================================================================== */
  /**
  * Remove the gray background on active links in IE 10.
  */
  a {
    background-color: transparent;
  }
  
  /**
  * 1. Remove the bottom border in Chrome 57-
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  */
  abbr[title] {
    border-bottom: none;
    /* 1 */
    text-decoration: underline;
    /* 2 */
    text-decoration: underline dotted;
    /* 2 */
  }
  
  /**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
  b,
  strong {
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
  }
  
  /**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    /* 1 */
    font-size: 1em;
    /* 2 */
  }
  
  /**
  * Add the correct font size in all browsers.
  */
  small {
    font-size: 80%;
  }
  
  /**
  * Prevent `sub` and `sup` elements from affecting the line height in
  * all browsers.
  */
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -0.25em;
  }
  
  sup {
    top: -0.5em;
  }
  
  /* Embedded content
   ========================================================================== */
  /**
  * Remove the border on images inside links in IE 10.
  */
  img {
    border-style: none;
  }
  
  /* Forms
   ========================================================================== */
  /**
  * 1. Change the font styles in all browsers.
  * 2. Remove the margin in Firefox and Safari.
  */
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    /* 1 */
    font-size: 100%;
    /* 1 */
    line-height: 1.15;
    /* 1 */
    margin: 0;
    /* 2 */
  }
  
  /**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */
  button,
  input {
    /* 1 */
    overflow: visible;
  }
  
  /**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */
  button,
  select {
    /* 1 */
    text-transform: none;
  }
  
  /**
  * Correct the inability to style clickable types in iOS and Safari.
  */
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  /**
  * Remove the inner border and padding in Firefox.
  */
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  
  /**
  * Restore the focus styles unset by the previous rule.
  */
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  
  /**
  * Correct the padding in Firefox.
  */
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  
  /**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */
  legend {
    box-sizing: border-box;
    /* 1 */
    color: inherit;
    /* 2 */
    display: table;
    /* 1 */
    max-width: 100%;
    /* 1 */
    padding: 0;
    /* 3 */
    white-space: normal;
    /* 1 */
  }
  
  /**
  * Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */
  progress {
    vertical-align: baseline;
  }
  
  /**
  * Remove the default vertical scrollbar in IE 10+.
  */
  textarea {
    overflow: auto;
  }
  
  /**
  * 1. Add the correct box sizing in IE 10.
  * 2. Remove the padding in IE 10.
  */
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
  }
  
  /**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  /**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
  [type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */
  }
  
  /**
  * Remove the inner padding in Chrome and Safari on macOS.
  */
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  /**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */
  }
  
  /* Interactive
   ========================================================================== */
  /*
  * Add the correct display in Edge, IE 10+, and Firefox.
  */
  details {
    display: block;
  }
  
  /*
  * Add the correct display in all browsers.
  */
  summary {
    display: list-item;
  }
  
  /* Misc
   ========================================================================== */
  /**
  * Add the correct display in IE 10+.
  */
  textarea {
    resize: none;
  }
  template {
    display: none;
  }
  
  /**
  * Add the correct display in IE 10.
  */
  [hidden] {
    display: none;
  }
  
  * {
    box-sizing: border-box;
  }
  
  img {
    vertical-align: middle;
  }
  
  html {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .05px;
  }
  
  body {
    width: 100%;
    min-width: 320px;
    transition: all 0.3s;
    font-family: 'Poppins', sans-serif;
  }
  
  div {
    display: block;
  }
  
  div:focus {
    outline: 0;
  }
  
  p:focus {
    outline: 0;
  }
  
  img:focus {
    outline: 0;
  }
  
  i:focus {
    outline: 0;
  }
  
  button {
    outline: none;
    border: 0;
    cursor: pointer;
    letter-spacing: .5px;
  }
  
  a {
    cursor: pointer;
    text-decoration: none;
  }
  
  p {
    line-height: 1.5;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
  }
  input, textarea {border:0;outline:0;}
  input, textarea:focus {outline:none!important;}
  
  
  
  input:focus,
  button:focus,
  textarea:focus {
    outline: none;
  }
  
  @media (max-width: 768px) {
    html {
        font-size: 14px;
    }
  }
  
  :root {
    /* --purple: #8F6FF7;
    --dark_purple: #322267;
    --light_text: #F1F5FD;
    --grey_text: #7A7980;
    --red: #EB5757;
    --green: #31F8C9;
    --light_purple: #DDD5FC;
    --light_grey_text: #818C99; */
    --auth_bg: #7D7DF2;
    --green: #E0F87E;
    --dark_gray: #494C68;
    --dark_text: #2C2543;
    --red: #EB5757;
  }
  
  p {
    font-weight: 500;
  }
  main{
    width: 100%;
  }
  button:focus{
    opacity: 0.7;
  }
  input,
  textarea,
  button,
  select,
  div,
  p,
  a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .App {
    padding: 100px 0 0 0;
    text-align: center;
    overflow-x: hidden;
    /* max-width: 660px;
    margin-left: auto;
    margin-right: auto; */
  }
  .app_inner_pages{
    padding: 100px 80px 80px 80px;
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  .desktop_dialog_bg{
    background: rgba(62, 62, 62, 0.60);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}
.desktop_dialog_inner{
    position: fixed;
    border-radius: 35px;
    background: #FFF;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
    left: 50%;
    top : 50%;
    z-index: 9999;
    min-width: 360px;
    padding: 48px 61px 32px 61px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.dialog_title{
  color: #000;
  text-align: center;
  font-size: 21px;
  font-weight: 700;
  line-height: 120%;
  text-transform: capitalize;
}
.dialog_buttons{
  display: flex;
  justify-content: center;
}
.dialog_cancel{
  border-radius: 30px;
  border: 1px solid #F2F2F2;
  color: #2C2543;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  padding: 20px 32px;
  width: fit-content;
  margin-right: 8px;
}
.dialog_ok{
  width: fit-content;
  margin-left: 8px;
  border-radius: 30px;
  background: #E0F87E;
  color: #2C2543;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  padding: 20px 32px;
  max-height: 62px;
  position: relative;
  min-width: 100px;
}
.dialog_error{
  color: var(--red);
  text-align: center;
  font-size: 16px;
  margin-top: 16px;
  font-weight: 500;
  line-height: 120%;
}
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .red_rectangle{
    width: 18.34px;
    height: 18.34px;
    
    background: var(--red);
    transform: translate(-50%, 0) rotate(45deg);
    -ms-transform: translate(-50%, 0) rotate(45deg);
    margin-bottom: -12px;
    border-radius: 6px;
  
    position: relative;
    left: 50%;
  
  }
  .ReactCrop .ord-nw {
    opacity: 0;
  }
  .ReactCrop .ord-ne {
    opacity: 0;
  }
  .ReactCrop .ord-se {
    opacity: 0;
  }
  .ReactCrop .ord-sw {
    opacity: 0;
  }
  .ReactCrop__crop-selection {
    border: 1px solid #D9D9D9;
    border-radius: 60px;
  }
  .ReactCrop__drag-handle::after {
    height: 32px;
    width: 32px;
  }
  .ReactCrop__drag-handle{
    height: 32px;
    width: 32px;
  }
  .center_vertical{
    position: relative;
    top: 50%;
    -ms-transform: translate(0, 50%);
    transform: translate(0, 50%);
  }
  .center_horizontal{
    position: relative;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .full_center{
    position: relative;
    left: 50%;
    top : 50%;
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
  }
 
  .relative{
    position: relative;
  }
  .flex{
    display: flex;
  }
  .button{
    margin: 0 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
    background: var(--green);
    border-radius: 16px;
    padding: 18px 0;
    width: calc(100% - 32px);
  
    
  }
  .bottom_button{
    position: absolute;
    bottom: 60px;
    left: 0px;
    max-height: 56px;
  }
  
  .apps_dropdown{
    background: #FFFFFF;
    border: 1px solid #E4ECEF;
    box-sizing: border-box;
    padding: 0px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: block;
    margin-left: -22px;
    position: absolute;
    z-index: 9999999;
    max-height: 428px;
    overflow-y: auto;
    width: 660px;
    top: 58px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: auto;
  }
  
  .filter_dropdown{
    margin: 36px 0px 0px -6px;
    top: auto;
    width: calc(100% - 40px);
   
  }
  .tooltip_bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -9;
  }
  
  .apps_line{
    padding: 8px 16px 8px 20px;
     cursor: pointer;
  }
  .apps_line:hover{
    background: #EAF4FA;
  }
  
  .apps_drop_text{
    margin: 2px 0px 0px 0px;font-size: 14px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
    color: black;
  }
  
  .rating_container{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
  
  .animation_in-enter {
    opacity: 0;
    transform: scale(0.95);
  }
  .animation_in-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .animation_in-exit {
    opacity: 1;
  }
  .animation_in-exit-active {
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 300ms, transform 300ms;
  }
  
  .no_horizontal_scroll::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no_horizontal_scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .justify-content{
    display: flex;
    justify-content: space-between;
  }
  
  .animation_opacity-enter {
    opacity: 0;
  }
  .animation_opacity-enter-active {
    opacity: 1;
    transition: opacity 100ms, transform 100ms;
  }
  .animation_opacity-exit {
    opacity: 1;
  }
  .animation_opacity-exit-active {
    opacity: 0;
    transition: opacity 100ms, transform 100ms;
  }
  .hover{
    transition: opacity 300ms;
    cursor: pointer;
  }
  @media (hover: hover) {
    .hover:hover{
        opacity: 0.7;
    }
   
  }
