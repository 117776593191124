.cards_container{
    margin: 68px 0px 60px 0px;
    padding: 52px 48px 32px 48px;
    border-radius: 35px;
    text-align: left;
    background: #FFF;
    color: var(--dark_text);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
}
.cards_header{
    display: flex;
    justify-content: space-between;

}
.cards_title{
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
}
.cadrs_signed_in{
    text-align: right;
    font-size: 18px;
    font-family: Poppins;
    line-height: 90%;
}
.cards_sign_out{
    color: var(--auth_bg);
    margin-left: 16px;
}
.cards_toolbar_container{
    position: relative;
    border-radius: 30px;
    border: 1px solid #F2F2F2;
    padding: 4px;
    
    display: flex;
    width: fit-content;
    height: 52px;
    margin-top: 21px;
    margin-bottom: 36px;
}
.cards_toolbar_item{
    position: relative;
    z-index: 1;
    color: var(--dark_text);
    font-size: 18px;
    font-weight: 500;
    padding: 7px 18px;
    margin-top: 2px;
}
.cards_toolbar_item_bg{
    border-radius: 30px;
    background: #DFF87E;
    width: 119px;
    height: 42px;
    top: 4px;
    left: 0px;
    margin-left: 4px;
    position: absolute;
    z-index: 1;
    transition: all 300ms;
}
.cards_selected_card_cont{
    display: flex;
    width: 100%;
    margin-left: 64px;
    
}
.cards_my_container{
    display: flex;
    justify-content: space-between;
}
.cards_list_container{
    text-align: center;
    max-height: 525px;
    overflow-y: scroll;
    padding: 4px 47px 4px 4px;
}
.cards_list_line{
    width: 3px;
    height: 525px;
    background: #F2F2F2;
}
.cards_card_border{
    border-radius: 10px;
    /* border: 4px solid #7D7DF2; */
    outline: 4px solid #7D7DF2;
    transition: outline 100ms;
}
.cards_card{
    height: 98px;
    width: 152px;
    padding: 2px;
    margin-bottom: 9px;
    position: relative;
}
.cards_selected_card{
    width: 413px;
    height: 260.545px;
    border-radius: 15.25px;
    margin-right: 56px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}
.cards_selected_last_digits{
    color: #2C2543;
    font-size: 23px;
    position: absolute;
    top: 178px;
    left: 25px;
    font-weight: 400;
}
.cards_card_img{
    object-fit: cover;
    margin-top: 1px;
    width: 144.714px;
    height: 91.294px;
    border-radius: 5.294px;
    background: #E7EEF4;
}
.cards_card_balance{
    position: absolute;
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
    left: 8px;
    top: 40px;
    text-align: left;
}
.cards_selected_line{
    background: #F2F2F2;
    height: 3px;
    width: 100%;
}
.cards_selected_balance{
    color: #2C2543;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    line-height: 110%;
}
.cards_selected_balance_info{
    color: #2C2543;
    font-size: 48px;
    font-weight: 600;
    margin: 5px 0 21px 0;
    text-align: left;
    min-height: 40px;
}
.cards_card_selected_balance{
    position: absolute;
    color: #FFF;
    font-size: 60px;
    font-weight: 600;
    left: 26px;
    top: 87px;
    text-align: left;
}
.cards_empty_transactions{
    color: #2C2543;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    line-height: 110%;
    margin-bottom: 4px;
}
.cards_transactions{
    margin-top: 34px;
}
.cards_transactions_item{
    margin-top: 10px;
    text-align: left;
}
.cards_transactions_date{
    color: #2C2543;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.3;
    margin-bottom: 10px;
}
.cards_transactions_row{
    display: flex;
    justify-content: space-between;
}
.cards_transactions_merchant{
    color: #2C2543;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
}
.cards_transactions_more{
    margin-top: 16px;
    border-radius: 30px;
    background: #E0F87E;
    color: #2C2543;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 20px;
    width: fit-content;
}
.cards_lock_card{
    width: 40px;
    height: 40px;
    margin: 40px 0 5px -28px;
}
.cards_lock_title{
    color: #2C2543;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-left: -28px;
}
.cards_funds{
   position: absolute;
   text-align: center;
   color: #2C2543;
   font-size: 12px;
   font-weight: 400;
   opacity: 0.3;
   bottom: 0px;
   left: -28px;
    width: 100%;
    
}
.cards_dialog_buttons{
    margin-top: 83px;
}
.cards_locked_card{
    background: rgba(44, 37, 67, 0.9);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 56px;
    border-radius: 0 0 16px 16px;
    padding: 27px 16px 16px 16px;
    text-align: center;
    color: white;
}
.cards_locked_card_title{
    font-size: 16px;
    font-weight: 400;
}
.cards_locked_card_valid{
    margin-top: 12px;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.cards_locked_phone{
    margin-top: 105px;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
}
.cards_empty_img{
    width: 240px;
    height: 240px;
    margin: 123px 0 16px 0;
}
.cards_empty_title{
    color: #2C2543;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 160px;
}