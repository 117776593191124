.navbar{
    position: fixed;
    width: 100vw;
    top: 0;
    padding: 0 17px 0 0;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    z-index: 2;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}
.navbar-logo{
    width: 212px;
    height: 78.86px;
    margin: 12px 0 9px 22px;
}
.navbar-link{
    font-weight: 500;
    font-size: 18px;
    line-height: 90%;
    text-align: center;
    color: #2C2543;
    padding: 12px;
    height: fit-content;
    margin: 29px 3px 0 3px;
}
.navbar-items{
    display: flex;
}
.navbar_burger{
    display: none;
}
.navbar_cards{
    color: #7D7DF2;
    font-weight: 700;
    font-size: 18px;
    line-height: 90%;
    text-align: center;
    padding: 12px 0 10px 0;
    height: -moz-fit-content;
    height: fit-content;
    margin: 29px 15px 0 3px;
    width: auto;
    border-bottom: 4px solid #7D7DF2;
}
@media (min-width: 320px) {
    .navbar{
        max-height: 80px;
        transition: max-height 0.3s ease-out;
    } 
    .navbar-link-mob{
        display: none;
    }
    .navbar_burger{
        display: block;
        position: absolute;
        right: 16px;
        top: 20px;
    }
    .navbar-items{
        display: none;
    }
    .navbar_burger{
        display: block;
    }
    .navbar-logo{
        width: 150px;
        height: 56px;
        margin: 12px 0 12px 9px;
    }
    .navbar-items-mob{
        display: block;
        margin-top: 100px;
        width: 100vw;
        margin-left: -150px;
        padding-bottom: 62px;
        transition: all 0.3s ease-out;
    }
    .navbar-link {
        font-weight: 500;
        font-size: 18px;
        line-height: 90%;
        text-align: center;
        color: #2C2543;
        padding: 12px;
        height: -moz-fit-content;
        height: fit-content;
        margin: 8px 0 0 0;
        
    }
}
@media (min-width: 640px) {
    .navbar{

    } 
    
}
@media (min-width: 960px) {
    .navbar{
        max-height: none;
    } 
    .navbar-link {
        font-weight: 500;
        font-size: 18px;
        line-height: 90%;
        text-align: center;
        color: #2C2543;
        padding: 12px;
        height: -moz-fit-content;
        height: fit-content;
        margin: 29px 3px 0 3px;
        width: auto;
    }
    .navbar-items-mob{
        display: none;
    }
    .navbar-link-mob{
        display: block;
    }
    .navbar-items{
        display: flex;
        margin-top: 0;
    }
    .navbar_burger{
        display: none;
    }
    .navbar-logo{
        width: 212px;
        height: 78.86px;
        margin: 12px 0 9px 22px;
    }
}

@media (min-width: 1440px) {
    .navbar{
        max-height: none;
        transition: none;
    } 
    .navbar-items{
        display: flex;
    }
    .navbar_burger{
        display: none;
    }
    .navbar-logo{
        width: 212px;
        height: 78.86px;
        margin: 12px 0 9px 22px;
    }
}