.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 22px;
    border: 3px solid var(--green);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--green) transparent transparent transparent;

  }
  .choose-app{
    margin-bottom: 0px;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  .loader_input {
    display: block;
    position : initial;
    margin-left: 4px;
    margin-right: 4px;
  }
.loader_container {
    margin: auto;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    bottom: 0;
}
.loader_chat_container{
    margin-top: 56px;
}

