.main{
    padding: 0 80px 80px 80px;
}
.landing-title{
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 110%;
    color: var(--dark_text);
    margin: 209px 0 0 60px; 
    text-align: left;
    margin-bottom: 174px;
}
.how-it-works{
    background: #DFF87E;
    border-radius: 35px;
    padding: 58px 30px 66px 30px;
    text-align: center;
    color: var(--dark_text);
    position: relative;
}

.how-it-works_title{
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
}

.how-it-works_desc{
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    margin: 10px 0 58px 0;
}
.how-it-works_desc span{
    font-weight: 700;
    text-decoration: underline;
}
.how-it-works_title span{
    color: #7D7DF2;
}
.how-it-works_images{
    display: flex;
    justify-content: space-between;
}
.how-it-works_item_icon{
    width: 186px;
    height: 220px;
    margin-bottom: 16px;
}
.how-it-works_item{
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
}
.how-it-works_item_info_icon{
    height: 14px;
    width: 14px;
}
.how-it-works_item_info_text{
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    margin: 0 0 0 6px;
}
.how-it-works_item_info{
    display: flex;
    justify-content: center;
    opacity: 0.5;
    margin-top: 16px;
}
.how-it-works_arrow{
    height: 220px;
    width: 40px;
}
.how-it-works_bottom_arrow{
    position: absolute;
    bottom: -110px;
    right: 138px;
    width: 82px;
    height: 149px;
}
.faq{
    text-align: center;
    color: var(--dark_text);
    padding-top: 250px;
}
.faq_title{
    position: relative;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #DFF87E;
    width: 100%;
}
.faq_title span{
    width: 100%;
    position: absolute;
    left: calc(50% - 3px);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    color: var(--dark_text);
    top: -3px;
}
.faq_bg{
    /* border: 10px dashed #E0F87E;
    border-radius: 50px; */
    margin: 52px auto 0 auto;
    max-width: 664px;
    max-height: 534px;
    position: relative;

    background-color: none !important;
    border: none !important;
    border: 10px dotted #E0F87E !important;
    border-radius: 50px;
    /* background-image: url("../img/landing/faq_bg.svg"); */
}
.faq_bg_image{
    width: 664px;
    position: absolute;
    left: 0;
    top: 0;
}
.faq_text{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    padding: 60px 65px 0 132px;
    text-align: left;
}
.faq_text span{
    margin-left: 10px;
}
.faq_girl{
    width: 333px;
    height: 372px;
    left: -46px;
    bottom: -120px;
    position: absolute;
}
.faq_card{
    width: 158px;
    height: 120px;
    margin: 20px 96px 102px 408px;
}
.faq-questions{
    background: #FDFF8D;
    border-radius: 35px;
    margin: 111px 0 92px 0;
    padding: 105px 236px;
    text-align: left;
    color: var(--dark_text);
}
.faq-questions_arrow{
    width: 16px;
    height: 8px;

    border: 2px solid var(--dark_text);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}
.faq-questions_item_cont{
    margin-bottom: 24px;
    border-bottom: 3px dashed rgba(44, 37, 67, 0.2);
}
.faq-questions_item{
    margin-left: 24px;
    margin-top: 12px;  
}
.faq-questions_arrow_cont{
    width: 46px;
    height: 44px;
    margin-bottom: 24px;
}
.faq-questions_item_answer_cont{
    max-height: 0;
    opacity: 1;
    transition: all 0.1s ease-out;
    overflow: hidden;
}
.faq-questions_item_answer{
    margin-top: 26px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
}

.faq-questions_title{
    font-weight: 700;
    font-size: 32px;
    line-height: 90%;
    margin-bottom: 45px;
}
.faq-questions_item_desc{
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
}
.faq-questions_more{
    border: 2px solid rgba(44, 37, 67, 0.2);
    border-radius: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 17px;
}
.faq-questions_more_icon{
    height: 14px;
    width: 14px;
    margin-left: 10px;
    margin-top: 5px;
}
.download_app{
    background: #7D7DF2;
    border-radius: 30px;
    display: flex;
    padding: 65px 0 0 137px;
    text-align: left;
    color: #FFFFFF;
}
.download_app_desc{
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
}
.download_app_info_icon{
    height: 14px;
    width: 14px;
    margin-right: 6px;
    margin-top: 8px;
}
.download_app_google{
    width: 221px;
    height: 67px;
    object-fit: cover;
    margin-left: 16px;
}
.download_app_store{
    width: 198px;
    height: 66px;
    object-fit: cover;
}
.download_app_trademark{
    font-weight: 400;
    font-size: 8px;
    line-height: 120%;
    opacity: 0.5;
    margin-top: 16px;
}
.download_app_info_text{
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    margin-top: 8px;
    margin-bottom: 25px;
    /* or 14px */
}
.download_app_title{
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    margin-bottom: 15px;
    margin-right: 32px;
    
}
.download_app_phone{
    width: 352px;
    height: 331px;
    object-fit: cover;
    margin-top: 20px;
    margin-right: 41px;
}

.need-help{
    background-color: none !important;
    border: none !important;
    border: 10px dotted #E0F87E !important;
    border-radius: 50px;


    position: relative;
    margin: 112px auto;
    max-width: 630px;
    padding: 120px 109px 86px 109px;
}
.need-help_bg_image{
    width: 630px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.need-help_title{
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    z-index: 1;
    margin-bottom: 66px;
}
.need-help_title_icon{
    position: absolute;
    width: 55.79px;
    height: 58.75px;
    top: -12px;
    left: -12px;
    z-index: -1;
}
.need-help_title_cont{
    width: 381px;
    position: relative;
    margin: 0 auto;
}
.need-help_input{
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    padding-bottom: 12px;
    border-bottom: solid rgba(73, 76, 104, 0.3) 1px;
    text-align: left;
    width: 414px;
    margin-bottom: 40px;
    transition: all 300ms;
}
.need-help_input::placeholder{
    color: rgba(73, 76, 104, 0.5);
}
.need-help_input:focus{
    border-bottom: solid var(--green) 1px;
}
.need-help_btn{
    background: var(--dark_text);
    box-shadow: 10px 10px 30px #DFF87E;
    border-radius: 15px;    
    margin-top: 23px;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    padding: 20px 30px;
    text-align: center;

    color: #FFFFFF;
}
.need-help_privacy{
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    opacity: 0.5;
    margin-top: 30px;
}
.need-help_privacy a{
    text-decoration: underline;
    color: var(--dark_text);
}
.footer{
    background: var(--dark_text);
    border-radius: 30px;
    padding: 62px 72px 55px 72px;
    color: #FFFFFF;
    text-align: left;
    position: relative;
}
.footer_logo{
    width: 212px;
    height: 78px;
    margin: -19px 0 0 -12px;
}
.footer_logo_cont{
    display: flex;
}
.footer_column_cont{
    display: flex;
}
.footer_link{
    font-weight: 500;
    font-size: 18px;
    line-height: 90%;
    padding: 6px 0;
    margin: 8px 0;
    color: #FFFFFF;
}
.footer_column_1{
    margin-left: 161px;
}
.footer_column_2{
    margin-left: 117px;
}
.footer_email_icon{
    height: 50px;
    width: 50px;
    position: absolute;
    top: 63px;
    right: 80px;
}
.footer_contants_info{
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    opacity: 0.5;
    margin: 16px 0 24px 0;
}
.footer_congratz{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    opacity: 0.5;
}
.footer_trademarks{
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    margin-top: 24px;
    opacity: 0.5;
}
.landing_pic_1{
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
}
.landing_pic_girl{
    position: absolute;
    right: -80px;
    bottom: -620px;
    z-index: -1;
}
.download_app_flex{
    display: flex;
}
.landing-url{
    text-decoration: underline;
    color: var(--dark_text)
}
.holidays{
    position: absolute;
    border-radius: 35px;
    background: #9E9EFF;
    top: 131px;
    left: 80px;
    right: 80px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;

}
.holidays_tag{
    border-radius: 46px;
    background: #7D7DF2;
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;
    padding: 8px 24px;
    width: max-content;
    margin-right: 16px;
    max-height: 46px;
    display: flex;
    /* min-width: max-content; */
}
.holidays_tags{
    /* display: flex; */
    max-width: calc(100vw - 160px - 288px);
    /* overflow-x: scroll; */
}
.download_app_a_tag{
    color: black;
    font-weight: 700;
    text-decoration: underline;
}
.holidays_arrow{
    width: 24px;
    margin-left: 8px;
    margin-top: 10px;
    /* margin-right: 16px; */
}
.holidays_gradient{
    position: absolute;
    width: 43px;
    height: 38px;
    flex-shrink: 0;
    left: calc(100vw - 160px - 352px);
}
.holidays_download{
    display: flex;
    color: #FFF;
    font-family: Poppins;
    font-size: 23px;
    font-weight: 800;
    line-height: 120%;
    padding: 0px 0px 0 0 ;
    margin-top: 8px;
}
.holidays_download_text{
    margin-left: 7px;
    margin-top: -2px;
}
@media (min-width: 320px) {
    .faq-questions {
        padding: 48px 16px;
        margin: 128px 0 92px 0;
    }
    .main{
        padding: 0;
    }
    .landing_pic_1 {
        height: 407px;
        right: 0px;
        top: 40px;
        content: url("../img/landing/landing_pic_1_mob.svg");
    }
    .how-it-works{
        padding: 58px;
        margin: 0 32px;
    }
    .how-it-works_desc{
        margin-bottom: 36px;
    }
    .how-it-works_item_icon{
        height: 189px;
        width: 156px;
    }
    .how-it-works_bottom_arrow{
        right: auto;
    }
    .faq{
        padding-top: 147px;
    }
   
    .faq_girl{
        bottom: -160px;
    }
    .download_app{
        text-align: center;
        padding: 65px 48px 0 48px;
        margin: 0;
    }
    .download_app_phone{
        display: none;
    }
    .download_app_flex{
        justify-content: center;
    }
    .download_app_phone_m{
        display: block;
        width: 280px;
        object-fit: cover;
        margin: 24px auto 0 auto;
    }
    .download_app_trademark{
        margin-top: 32px;
    }
    .need-help{
        position: relative;
        margin: 56px 8px 331px 8px;
        max-width: none;
        padding: 80px 43px;
    }
    .need-help_bg_image{
        width: calc(100vw - 16px);
        object-fit: fill;
        height: 595px;
    }
    .landing_pic_girl {
        right: -80px;
        bottom: -920px;
    }
    .need-help_title{
        margin-bottom: 29px;
    }
    .footer_logo_cont{
        display: block;
    }
    .footer_column_1 {
        margin-left: 0px;
        margin-top: 16px;
    }
    .footer_column_2 {
        margin-top: 8px;
        margin-left: 0;
    }
    .footer_email_icon{
        top: 146px;
        right: 32px;
    }
    .download_app_store{
        width: 141px;
        height: 45px;
    }
    .footer {
        padding: 62px 32px 55px 32px;
        border-radius: 30px 30px 0 0;
    }
    .footer_column_cont{
        display: block;
        margin-bottom: 56px;
    }
    .need-help_title_cont{
        width: 221px;
    }
    .need-help_input{
        width: -webkit-fill-available;
    }
    .need-help_input{
        font-size: 16px;
        line-height: 16px;
    }
    .need-help_btn{
        font-size: 16px;
    }
    .landing_pic_girl {
        right: -60px;
        bottom: -690px;
        width: 450px;
    }
    .faq_bg_image {
        max-width: 664px;
        width: calc(100vw - 16px);
        margin-left: 8px;
        height: 534px;
        object-fit: fill;
    } 
    .faq_text{
        padding: 48px 48px 0 48px;
        left: 50%;
        transform: translate(-50%, 0);
        max-width: 620px;
        position: relative;
    
    }  
    .faq_card {
        width: 111px;
        height: 85px;
        margin: 20px 96px 102px calc(100vw - 111px - 32px);
    }
    .faq_girl {
        width: 233px;
        height: 260px;
        left: 0px;
        bottom: -140px;
    }
    .how-it-works{
        padding: 58px;
        margin: 0 8px;
    }
    .how-it-works_images{
        display: block;
    }
    .how-it-works_desc{
        margin-bottom: 36px;
    }
    .how-it-works_item_icon{
        height: 189px;
        width: 156px;
    }
    .how-it-works_arrow{
        transform: rotate(90deg);
        margin: -60px 0;
    }
    .how-it-works_item_icon_2{
        margin-right: -30px;
    }
    .how-it-works_item_icon_3{
        margin-left: -30px;
    }
    .how-it-works_bottom_arrow{
        right: auto;
    }
    .landing-title {
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        margin-top: 164px;
        margin-bottom: 102px;
        margin-left: 16px;
        
    }
    .faq_bg {
        max-width: none;
        margin-left: 8px;
        margin-right: 8px;
    } 
    .download_app_google {
        width: 158px;
        height: 48px;
    }

    .holidays{
        position: absolute;
        border-radius: 0;
        top: 80px;
        left: 0px;
        right: 0px;
        padding: 12px 16px;
    
    }
    .holidays_arrow{
        margin-left: 0;
        margin-top: 8px;
    }
    .holidays_tags{
        /* display: flex; */
        max-width: calc(100vw - 20px);
        /* overflow-x: scroll; */
    }
    .holidays_gradient{
        position: absolute;
        width: 43px;
        height: 38px;
        flex-shrink: 0;
        left: calc(100vw - 70px);
    }
    .holidays_arrow_right{
        padding-right: 16px;
    }
    .holidays_download{
        font-size: 18px;
        margin-top: 8px;

    }
    .holidays_download_text{
        margin-top: 1px;
    }
    .holidays_tag{
        font-size: 16px;
    }
    .holidays_download_mob{
        display: none;
    }
}
@media (min-width: 640px) {
    .holidays_download_mob{
        display: block;
    }
    .holidays_arrow_right{
        padding-right: 0px;
    }
    .landing-title {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 110%;
        color: var(--dark_text);
        margin: 209px 0 0 60px;
        text-align: left;
        margin-bottom: 174px;
    }
    .download_app_store{
        width: 198px;
        height: 66px;
    }
    .download_app_google {
        width: 221px;
        height: 67px;
        object-fit: cover;
        margin-left: 16px;
    }
    .need-help_title{
        margin-bottom: 66px;
    }
    .need-help_input{
        width: -webkit-fill-available;
    }
    .how-it-works_images{
        display: block;
    }
    .how-it-works{
        padding-left: 16px;
        padding-right: 16px;
    }
   
    .main{
        padding: 0;
    }
    .faq-questions {
        padding: 48px;
    }
    .landing_pic_1{
        height: 581px;
        right: 0px;
        top: 60px;
        content: url("../img/landing/landing_pic_1.svg");
    }
    .landing-title{
        margin-bottom: 102px;
        margin-top: 226px;
    }
    .how-it-works{
        padding: 58px;
        margin: 0 32px;
    }
    .how-it-works_desc{
        margin-bottom: 36px;
    }
    .how-it-works_item_icon{
        height: 189px;
        width: 156px;
    }
    .how-it-works_arrow{
        transform: rotate(90deg);
        margin: -60px 0;
    }
    .how-it-works_item_icon_2{
        margin-right: -30px;
    }
    .how-it-works_item_icon_3{
        margin-left: -30px;
    }
    .need-help_title_cont{
        width: 381px;
    }
    .how-it-works_bottom_arrow{
        right: auto;
    }
    .faq{
        padding-top: 147px;
    }
    .faq-questions {
        margin: 128px 32px 92px 32px;
    }
   
  
    .download_app{
        text-align: center;
        padding: 65px 48px 0 48px;
        margin: 0 32px;
    }
    .download_app_phone{
        display: none;
    }
    .download_app_flex{
        justify-content: center;
    }
    .download_app_phone_m{
        display: block;
        width: 280px;
        object-fit: cover;
        margin: 24px auto 0 auto;
    }
    .download_app_trademark{
        margin-top: 32px;
    }
    .need-help{
        position: relative;
        margin: 56px 32px 331px 32px;
        max-width: none;
        padding: 120px 109px 86px 109px;
    }
    .need-help_bg_image{
        width: calc(100vw - 64px);
        object-fit: fill;
        height: auto;
    }
    
    .landing_pic_girl {
        right: -80px;
        bottom: -920px;
        width: auto;
    }
   
    .footer_logo_cont{
        display: block;
    }
    .footer_column_1 {
        margin-left: 0px;
        margin-top: 16px;
    }
    .footer_column_2 {
        margin-top: 16px;
        margin-left: 156px;
    }
    .footer_email_icon{
        top: 146px;
        right: 32px;
    }
    .faq_text{
        padding: 60px 65px 0 132px;
    }
    .footer {
        padding: 62px 32px 55px 32px;
        border-radius: 30px 30px 0 0;
    }
    .footer_column_cont{
        display: flex;
    }
    .need-help_input{
        font-size: 22px;
        line-height: 22px;
    }
    .need-help_btn{
        font-size: 22px;
    }
    .faq_bg_image {
        max-width: 664px;
        width: calc(100vw - 64px);
        left: 50%;
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        
    }  
    .faq_bg {
        max-width: none;
        margin-left: auto;
        margin-right: auto;
    } 
    .faq_card {
        width: 158px;
        height: 120px;
        margin: 20px 96px 102px 408px;
    }
    .faq_girl {
        width: 333px;
        height: 372px;
        left: 0px;
        bottom: -160px;
    }

    .holidays{
        position: absolute;
        border-radius: 0;
        top: 80px;
        left: 0px;
        right: 0px;
        
        padding: 12px 16px;
    
    }
    .holidays_arrow{
        margin-left: 0;
        margin-top: 8px;
    }
    .holidays_tags{
        /* display: flex; */
        max-width: calc(100vw - 224px);
        /* overflow-x: scroll; */
    }
    .holidays_gradient{
        position: absolute;
        width: 43px;
        height: 38px;
        flex-shrink: 0;
        left: calc(100vw - 272px);
    }
    .holidays_download{
        font-size: 18px;
        margin-top: 8px;
    }
    .holidays_download_text{
        margin-top: 1px;
    }
    .holidays_tag{
        font-size: 16px;
    }
}
@media (min-width: 960px) {

    .holidays{
        position: absolute;
        border-radius: 35px;
        background: #9E9EFF;
        top: 131px;
        left: 80px;
        right: 80px;
        color: white;
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
    
    }
    .holidays_tag{
        border-radius: 46px;
        background: #7D7DF2;
        color: #FFF;
        font-size: 18px;
        font-weight: 700;
        line-height: 120%;
        padding: 8px 24px;
        width: max-content;
        margin-right: 16px;
        max-height: 46px;
        display: flex;
        /* min-width: max-content; */
    }
    .holidays_tags{
        /* display: flex; */
        max-width: calc(100vw - 160px - 288px);
        /* overflow-x: scroll; */
    }
    .holidays_arrow{
        width: 24px;
        margin-left: 8px;
        margin-top: 10px;
    }
    .holidays_gradient{
        position: absolute;
        width: 43px;
        height: 38px;
        flex-shrink: 0;
        left: calc(100vw - 160px - 338px);
    }
    .holidays_download{
        display: flex;
        color: #FFF;
        font-family: Poppins;
        font-size: 23px;
        font-weight: 800;
        line-height: 120%;
        padding: 0px 0px 0 0 ;
        margin-top: 8px;
    }
    .holidays_download_text{
        margin-left: 7px;
        margin-top: -2px;
    }
    .faq_bg {
        max-width: 664px;
        
    }

    .how-it-works_arrow{
        transform: rotate(0deg);
        margin: 0;
    }
    .faq_bg_image {
        max-width: 664px;
        width: calc(100vw - 64px);
        margin-left: 0;
    }
    .need-help_input{
        width: 414px;
    }
    .how-it-works_images{
        display: flex;
    }
    .how-it-works{
        padding-left: 16px;
        padding-right: 16px;
        margin: 0;
    }
    .main{
        padding: 0 32px 80px 32px;
    }
    .faq-questions {
        padding: 64px 45px;
    }
    .download_app {
        padding: 65px 0 0 31px;
        text-align: left;
    }

    .landing_pic_girl {
        right: -100px;
        bottom: -560px;
        height: 875px;
    }
    .landing_pic_1{
        height: 681px;
        right: 0px;
    }
    .landing-title{
        margin-bottom: 141px;
        font-size: 65px;
    }
    .footer_logo_cont{
        display: block;
    }
    .footer_column_1 {
        margin-left: 0px;
        margin-top: 16px;
    }
    .footer_column_2 {
        margin-top: 16px;
        margin-left: 156px;
    }
    .footer_email_icon{
        top: 146px;
        right: 32px;
    }
    .footer {
        padding: 62px 32px 55px 32px;
        border-radius: 30px;
    }
    .how-it-works_item_icon_2{
        margin-right: 0;
    }
    .how-it-works_item_icon_3{
        margin: 0;
    }
    .how-it-works_bottom_arrow{
        right: 138px;
    }
    .faq{
        padding-top: 250px;
    }
    .faq-questions {
        margin: 111px 0 92px 0;
    }
    .faq_girl{
        bottom: -160px;
        left: -60px;
    }
    .download_app{
        padding: 65px 0 0 137px;
        margin: 0;
    }
    
    .download_app_flex{
        justify-content: left;
    }
    .download_app_phone{
        display: block;
    }
    .download_app_phone_m{
        display: none;
    }
    .download_app_trademark{
        margin-top: 16px;
    }
    .need-help{
        position: relative;
        margin: 112px auto;
        max-width: 630px;
        padding: 120px 109px 86px 109px;
    }
    .need-help_bg_image{
        width: 630px;
    }
    .landing_pic_girl {
        right: -80px;
        bottom: -620px;
    }
   
}

@media (min-width: 1440px) {
    .how-it-works_images{
        display: flex;
    }
    .how-it-works{
        padding: 58px 30px 66px 30px;
    }
    .main{
        padding: 0 80px 80px 80px;
    }
    .faq-questions {
        padding: 105px 236px;
    }
    .landing_pic_1{
        right: 0;
        height: 781px;
        content: url("../img/landing/landing_pic_1440.svg");        
       
    }
    .landing-title{
        margin-bottom: 174px;
    }
    .footer_logo_cont{
        display: flex;
    }
    .footer_column_1 {
        margin-left: 161px;
        margin-top: 0px;
    }
    .footer_column_2 {
        margin-top: 0px;
    }
    .footer_email_icon{
        top: 63px;
        right: 80px;
    }
    .footer {
        padding: 62px 72px 55px 72px;
    }
}


